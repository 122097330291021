@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Inter:wght@400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #E5E5E5;
}

body {
  margin: 0 auto;
  max-width: 1600px;
}

a {
  position: relative;
  color: #181717;
  text-decoration: none;
}

a:hover {
  color: #181717;
}

a:not(.active)::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #181717;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

a:hover::before {
  transform: scaleX(1);
}

a.active {
  border-bottom: 2px solid #181717;
  padding-bottom: 4px;
}

.divided {
  border-top: 2px solid #c3c3c3;
}

#arrow {
  transform: rotate(-90deg);
  position: absolute;
  left: 165px;
  bottom: 23px;
}

input[type='text'], textarea {
  background-color: #E5E5E5;
}

::-webkit-scrollbar {display:none;}

